import React from "react";
import { Rating } from "../atoms";
import { CarRatingElement } from "../atoms";

export const CarRating = (props) => {
  return (
    <div id="car_rating" className="mt-3 text-sm-left text-center">
      <div id="users_rating">
        <div className="row d-flex align-items-center">
          <div className="col-xl-4 font-weight-bold text-center text-md-left mb-2">
            <h6 className={'font-weight-bold mb-0'}>Valoraciones de usuario</h6>
          </div>
          <div className="col-xl-3 font-weight-bold text-center text-md-left mb-2">
            <h6 className={'color_blue font-weight-normal mb-0'}>Dejanos tu valoración</h6>
          </div>
          <div className="col-xl-2 text-center text-md-left mb-2">
            <Rating rating="4.1" />
          </div>
          <div className="col-xl-3 d-flex flex-column mb-2">
            <div className="d-flex align-items-center justify-content-center ">
              <h2 className="font-weight-bolder mr-2 mb-0">4.1</h2>
              <span className="font-weight-bold">Score Usuarios</span>
            </div>
            <span className="my-auto text-center color_blue font-italic">
              Basado en 17 valoraciones
            </span>
          </div>
        </div>
      </div>

      <div id="sm_divider" className="my-3 divider-sm"></div>

      <div id="users_rating_details">
        <div className="row">
          <div className="col-xl-6">
            <CarRatingElement title="Valor" rating="4" />
          </div>
          <div className="col-xl-6">
            <CarRatingElement title="Calidad" rating="4" />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <CarRatingElement title="Confiabilidad" rating="4.1" />
          </div>
          <div className="col-xl-6">
            <CarRatingElement title="Performance" rating="4.5" />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <CarRatingElement title="Confort" rating="4.2" />
          </div>
          <div className="col-xl-6">
            <CarRatingElement title="Estilo" rating="4.8" />
          </div>
        </div>
      </div>

      <div id="sm_divider" className="my-3 divider-sm"></div>
    </div>
  );
};
