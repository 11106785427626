import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function GoogleAnalytics({ children }) {
	let location = useLocation()

	useEffect(() => {
		window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS, {
			page_path: location.pathname,
		})
	}, [location])

	return <>{children}</>
}
