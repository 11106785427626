import React, { useState } from "react";
import { PriceSelectPill } from "./PriceSelectPill";
import { SquareButton } from "./SquareButton";
import infoProLogo from "../../static/img/infopro_blanco.png";

export const PlanCard = ({
  title,
  prices,
  suscribed,
  onSuscribe,
  subscription,
  subscriptions,
}) => {
  const [selectedPrice, setSelectedPrice] = useState(0);
  const pro = title.includes("InfoPro");
  return (
    <div className={"plan_card_container"}>
      <div className={"plan_card default_shadow background_gray"}>
        <h3
          className={`bg_gray text-white text-center p-2 font-weight-bold ${
            title === "Premium" && "bg_black"
          } ${pro && "bg_blue"}`}
        >
          {pro && <img src={infoProLogo} style={{ maxWidth: 100 }} alt="" />}
          {pro ? title.substring(7, 20) : title}
        </h3>
        <div className="body p-4 d-flex flex-column align-items-center">
          <p>
            Registrate <span className="font-weight-bold">sin cargo</span> y
            consultá precios de todos los 0km del mercado
          </p>
          {title === "Particular" && (
            <div
              className={
                "d-flex border-bottom w-100 justify-content-center py-4"
              }
            >
              <h3 className={"font-weight-bold"}>$</h3>
              <h1 className={"font-weight-bold display-4"}>{prices[0]}</h1>
            </div>
          )}
          {title !== "Particular" && (
            <div className={"border-bottom w-100"}>
              <div className={"d-flex w-100 justify-content-center"}>
                <h3 className={"font-weight-bold"}>$</h3>
                <h1 className={"font-weight-bold display-4"}>
                  {subscription[selectedPrice].price}
                </h1>
              </div>
              <div className="d-flex justify-content-center">
                <p className="font-weight-bold color_blue">PRECIO</p>
                <PriceSelectPill
                  selectedPrice={selectedPrice}
                  setSelectedPrice={setSelectedPrice}
                />
              </div>
            </div>
          )}

          <div
            className={
              "d-flex border-bottom w-100 py-4 flex-column align-items-center justify-content-center"
            }
          >
            <p className={"font-weight-bold"}>Incluye</p>
            <p className="mb-1">
              Consulta 0km modo{" "}
              <span className="font-weight-bold">Ilimitado</span>
            </p>
            <p className="mb-1">
              Ficha técnica <span className="font-weight-bold">Básica</span>
            </p>
            <p className="mb-1">
              Hasta <strong>3</strong> consultas de usados
            </p>
            <p className="mb-1">
              <strong>(auto & moto)</strong>
            </p>
          </div>
          <div
            className={
              "d-flex w-100 py-4 flex-column align-items-center justify-content-center"
            }
          >
            <p className={"font-weight-bold"}>Características</p>
            <p className="mb-1">Descripción Marca / Modelo / Versión</p>
            <p className="mb-1">Comparador hasta 2 Unidades.</p>
          </div>
          {!suscribed && (
            <div className="w-75">
              <SquareButton
                onClick={() => onSuscribe(subscription[selectedPrice])}
                text="Suscribite"
              />
            </div>
          )}
        </div>
      </div>
      {title === "Premium" && (
        <div className="premium_text d-flex w-100 justify-content-center">
          <p className={"mb-0 px-4 py-1"}>El plan más completo</p>
        </div>
      )}
    </div>
  );
};
